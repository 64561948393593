import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { get } from "lodash";
import Layout from "@components/Layout";
import SEO from "@components/seo";
import { truncateDescription, markupToText } from "../../utils/helpers";
import "@scss/main.scss";

export default function index({ data }) {
  // console.log(data);
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [featuredArticle, setFeaturedArticle] = useState(null);
  const [featuredArticleImage, setFeaturedArticleImage] = useState(null);
  const categories = get(data, 'categories.nodes', null);
  // console.log(categories);
  useEffect(() => {
    // console.group("useEffect");

    const featured = get(data, "featuredArticle", null);
    const image = getImage(featured.featuredImage.node.localFile);
    const featuredId = get(featured, "id", null);
    const postsData = get(data, "allWpPost.edges", null);
    // reduce the posts data to only not equal to featured article id
    const filteredPosts = postsData.filter(
      (post) => post.node.id !== featuredId
    );
    // console.log(filteredPosts);
    // console.groupEnd();
    setFeaturedArticle(featured);
    setFeaturedArticleImage(image);
    setPosts(filteredPosts);
    setLoading(false);
  }, []);

  const getDescription = (post) => {
    // console.dir(post);
    const postDescription = get(post, "posts.postDescription", null);
    const description = markupToText(postDescription);
    const newDescription = truncateDescription(description, 200);
    const excerpt = markupToText(get(post, 'excerpt', ""));
    const newExcerpt = truncateDescription(excerpt, 200);

    if(newDescription) {
      return newDescription;
    }
    return newExcerpt;
  }

  return (
    <Layout baseRoute="blog" blogCategories={categories}>
      <SEO title="Blog" />
      <h1>Blog</h1>
      <div className="categories-wrapper">
        {categories &&
          categories.map((category) => (
            <Link key={category.name} to={category.link}>
              {category.name}
            </Link>
          ))}
      </div>
      <div className="contained">
        <h2>Featured Article</h2>
        <div id="featured-article-wrapper" className="bordered">
          {!loading && (
            <>
              <Link
                to={`${featuredArticle.uri}`}
                className="title"
                alt={featuredArticle.title}
              >
                <GatsbyImage
                  layout="constrained"
                  image={featuredArticleImage}
                  alt={featuredArticle.title}
                />
                <h3>{featuredArticle.title}</h3>
              </Link>
              <div className="description">
                {getDescription(featuredArticle)}

                <div className="read-more-wrapper">
                  <span className="date">{featuredArticle.date}</span>
                  <Link to={featuredArticle.uri} className="read-more">
                    Read More ➢
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>

        <h2>Latest Posts</h2>
        <div id="posts-wrapper">
          {!loading &&
            posts.map((post) => {
              return (
                <div key={post.node.id} className="bordered">
                  <Link
                    to={`${post.node.uri}`}
                    className="title"
                    alt={post.node.altText}
                    key={post.node.id}
                  >
                    {post.node.altText}
                    <GatsbyImage
                      layout="constrained"
                      image={getImage(post.node.featuredImage.node.localFile)}
                      alt={post.node.featuredImage.node.altText}
                    />
                    <h3>{post.node.title}</h3>
                  </Link>
                  <div className="description">
                    {getDescription(post.node)}
                    <div className="read-more-wrapper">
                      <span className="date">{post.node.date}</span>
                      <Link to={post.node.uri} className="read-more">
                        Read More ➢
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    allWpPost(limit: 3, sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          date(formatString: "MMMM D, YYYY")
          slug
          excerpt
          uri
          posts {
            postDescription
          }
          featuredImage {
            node {
              altText
              caption
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: { fit: COVER }
                    backgroundColor: "transparent"
                    layout: CONSTRAINED
                    aspectRatio: 1.78
                  )
                }
              }
              description
            }
          }
        }
      }
    }
    featuredArticle: wpPost(isSticky: { eq: true }) {
      id
      slug
      excerpt
      date(formatString: "MMMM D, YYYY")
      featuredImage {
        node {
          altText
          caption
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { fit: COVER }
                backgroundColor: "transparent"
                layout: CONSTRAINED
                aspectRatio: 1.78
              )
            }
          }
        }
      }
      uri
      posts {
        postDescription
      }
      title
    }
    categories: allWpCategory(filter: { count: { gt: 0 } }) {
      nodes {
        count
        name
        link
      }
    }
  }
`;
